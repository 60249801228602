<template>
  <div>
    <!--{{categories}}-->
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 pt-4 pb-4">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a href="#" @click.prevent>{{ $t('dashboard') }}</a></li>
            <li class="breadcrumb-item active" aria-current="page" @click.prevent>{{ $t('users') }}
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="dashboard_container">
          <div class="row">
            <div class="col-12 pt-3">
              <h2 class="font-weight-bold fun_font text-center">{{ $t('users') }}</h2>
            </div>
          </div>
          <div class="dashboard_container_header">
            <button class="btn btn-danger-light" @click="showAddModal()">{{ $t('add_user') }}</button>

            <!--                  <form class="form-inline my-2 my-lg-0" dir="ltr" @submit.prevent="getAllUsers()">-->
            <!--                    <input class="form-control" type="search" :placeholder="$t('search')" v-model="query" aria-label="Search"/>-->
            <!--                    <button class="btn my-2 my-sm-0" type="submit">-->
            <!--                      <i class="ti-search"></i>-->
            <!--                    </button>-->
            <!--                  </form>-->
          </div>

          <div class="dashboard_container_body">
            <div class="table-responsive">
              <table class="table">
                <thead class="thead-default">
                <tr>
                  <th class="text-left ">#</th>
                  <th class="text-left ">{{ $t('username') }}</th>
                  <th class="text-left ">{{ $t('email') }}</th>
                  <th class="text-left ">{{ $t('role') }}</th>
                  <th class="text-left ">{{ $t('adding_date') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(user , index) in users" :key="index">
                  <td>{{ offset + index + 1 }}</td>
                  <td>{{ user.username }}</td>
                  <td>{{ user.email }}</td>
                  <td>{{ user.roles.name_ar }}</td>
                  <td>{{ parseDate(user.createdAt) }}</td>
                </tr>
                </tbody>
              </table>
              <p class="text-lg-center font-weight-bold" v-if="users.length==0">{{ $t('no_data') }}</p>
            </div>

            <div class="text-center" v-if="totalCount > limit">
              <paginate
                  :page-count="Math.ceil(totalCount / limit)"
                  :page-range="3"
                  :click-handler="handlePaginate"
                  :prev-text="'Prev'"
                  :next-text="'Next'"
                  :container-class="'pagination'"
                  :page-class="'page-item'">
              </paginate>
            </div>

          </div>
        </div>
      </div>
    </div>

    <sweet-modal ref="addUserModalRef" overlay-theme="dark" modal-theme="dark">
      <h4 class="text-white">{{ 'إضافة مستخدم' }}</h4>
      <div class="row text-left">
        <div class="col-12">
          <label class="font-weight-bold">{{ $t('username') }}</label>
          <input type="text" v-validate="'required'" v-model="newUser.username" class="form-control" :placeholder="$t('username')">
        </div>

        <div class="col-12 mt-2">
          <label class="font-weight-bold">{{ $t('email') }}</label>
          <input type="email" v-validate="'required|email'" v-model="newUser.email" class="form-control" :placeholder="$t('email')">
        </div>

        <div class="col-12 mt-2">
          <label class="font-weight-bold">{{ $t('password') }}</label>
          <input type="text" v-validate="'required|numeric|min:6'" v-model="newUser.password" class="form-control" :placeholder="$t('password')">
        </div>

        <div class="col-12 mt-2">
          <label class="font-weight-bold">{{ $t('role') }}</label>
          <select v-validate="'required'" data-vv-name="role" v-model="newUser.role" class="form-control">
            <option value="admin">{{ $t('admin_user') }}</option>
            <option value="technical">{{ $t('technical') }}</option>
            <option value="editor">{{ $t('editor') }}</option>
            <option value="owner">{{ $t('owner') }}</option>
            <option value="marketer">{{ $t('marketer') }}</option>
          </select>
        </div>

        <p class="text-danger col-12 mt-2" v-if="error">{{ error }}</p>
        <div class="col-12 mt-4">
          <button class="btn btn-success-light w-100" @click="addNewUser()">
            {{ $t('add') }}
          </button>
        </div>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue'
import moment from "moment/moment";

export default {
  name: "All",
  components: { SweetModal },
  data() {
    return {
      newUser: {
        username: '',
        email: '',
        password: '',
        role: '',
      },
      users: [],
      limit: 20,
      offset: 0,
      totalCount: 0,
      error: null,
    }
  },
  mounted() {
    this.getAllUsers();
  },
  methods: {
    handlePaginate(pageNum) {
      this.offset = (pageNum - 1) * this.limit;
      this.getAllUsers();
    },
    parseDate(date) {
      if (date) {
        return moment(date).format("DD-MM-YYYY  HH:mm");
      }
      return ' - ';
    },
    prepareFilters() {
      return {
        limit: this.limit,
        offset: this.offset,
      };
    },
    getAllUsers() {
      let vm = this;
      vm.$helper.showLoader();
      let filters = vm.prepareFilters();

      this.$store.dispatch('moduleUser/listUsers', filters).then((response) => {
        vm.users = response.data.data.rows || [];
        vm.totalCount = response.data.data.count || 0;
        vm.$helper.hideLoader();
      }).catch((error) => {
        vm.$helper.hideLoader();
        vm.$helper.handleError(error, vm);
      });
    },
    showAddModal() {
      this.newUser = {
        username: '',
        email: '',
        password: '',
        role: '',
      };

      this.$refs.addUserModalRef.open();
    },
    async addNewUser() {
      this.error = '';
      const result = await this.$validator.validateAll();

      if (result) {
        this.$refs.addUserModalRef.close();

        const response = await this.$store.dispatch("moduleUser/createNewUser", this.newUser).catch((error) => {
          this.$helper.handleError(error, this);
        });

        if (response.status === 200 || response.status === 201) {
          this.$helper.showMessage('success', this);
        }

        this.newUser = { username: '', email: '', password: '', role: '' };

        await this.getAllUsers();
      } else {
        this.error = 'تأكد من ادخال جميع الحقول بشكل صحيح';
      }
    },
  }
}
</script>

<style>
.sweet-modal .sweet-title > h2 {
  padding-top: 15px;
}
</style>